import { io } from "socket.io-client";
//export const url2 = "https://apiargon.vercel.app/";
//export const url = "https://nodeapi-dun.vercel.app/";
//export const url2 = "http://localhost:4000/";
//export const url = "http://localhost:9005/";

//require("dotenv").config();

export const url2 = process.env.REACT_APP_API_URL2;
export const url = process.env.REACT_APP_API_URL;
//jola
/* console.log("fdesd consolca.....");
console.log(process.env.REACT_APP_API_URL);
export const prueba = process.env.REACT_APP_PRUEBA; */

//alert("desde urls", process.env.REACT_APP_API_URL);

export const socket = io("https://chatjls-nn2z.vercel.app/", {
  transports: ["websocket"],
});
/* export const url = "https://api.gestoresambientales.com.ec/"; */

/* https://nodeapi-dun.vercel.app/ */
//export const urls= process.env.REACT_APP_URL_API;
//export const socket = io(process.env.REACT_APP_URL_API_SOCKET, { transports: ["websocket"] });
