import React, { useState } from "react";
import TablaPanelProductos from "../Componentes/PanelProductos.componentes/tabla.panelProductos";
import ModalDefault from "../Componentes/modalDefault";
import { ReactComponent as AdminIcon } from "../svg/admin.svg";
import CrearEditarProductos from "../Componentes/PanelProductos.componentes/crearEditarProductos";

const Productos = () => {
  const [modalCrearProductos, setmodalCrearProductos] = useState(false);
  const [actualiza, setActualiza] = useState(false);

  const modalActions = (modal, bandera) => {
    if (bandera === "crearproducto") {
      setmodalCrearProductos(modal);
    } else {
      alert("estoy fuera de crear producto");
    }
  };

  const actualizarTabla = () => {
    setmodalCrearProductos(false);
    setActualiza(!actualiza);
  };

  return (
    <>
      <div className="p-2 bg-white">
        <div>PanelProductos</div>

        <button
          className="btn btn-success mx-1"
          onClick={() => {
            setmodalCrearProductos(true);
          }}
        >
          Crear
        </button>

        <TablaPanelProductos actualizar={actualiza} />
        <ModalDefault
          bandera={"crearproductos"}
          modal={modalCrearProductos}
          size={"lg"}
          icontitulo={<AdminIcon />}
          titulo={`Crear Producto`}
          closeAction={modalActions}
        >
          <CrearEditarProductos crearrModalActualizar={actualizarTabla} />
        </ModalDefault>
      </div>
    </>
  );
};

export default Productos;
