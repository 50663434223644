import { toast } from "react-toastify";
import Axios from "../config/Axios";
import TokenAuth from "../config/Token";
export const authenticationfn = async (datos) => {
  try {
    const respuesta = await Axios.post("/login", datos);
    if (respuesta.data.sms === "ok") {
      let token = respuesta.data.data[0].token;
      localStorage.setItem("accesTokengestores", token);
      window.location = "/";
    } else {
      return [
        {
          sms: "err",
          data: [],
          mensaje: respuesta.data.mensaje,
        },
      ];
    }
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const authenticationDataFn = async () => {
  TokenAuth(localStorage.getItem("accesTokengestores"));
  try {
    const respuesta = await Axios.get("/dataUserLogin");
    if (respuesta.data.sms === "ok") {
      /*  respuesta.data.data.map(async (data, index) => {
        const firmaFoto = await Axios.post("/downloadStorageFile", {
          file: respuesta.data.data[index].personas[0].foto,
        });
        localStorage.setItem("fotoPerfilcmm", firmaFoto.data.url);
      }); */
      return [respuesta.data];
    } else {
      return [
        {
          sms: "err",
          data: [],
          mensaje: respuesta.data.mensaje,
        },
      ];
    }
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const tipoSangreDataFn = async () => {
  TokenAuth(localStorage.getItem("accesTokenCmm"));
  try {
    const respuesta = await Axios.get("/tipoSangre/");

    return [respuesta.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};
