import React, { useState } from "react";
import Axios from "../../config/Axios";
import authContext from "./authContext";
//import Swal from 'sweetalert2'

const AuthState = (props) => {
  //var data = [{id: 1}, {id: 2}]

  // Encrypt
  //var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123').toString();

  // Decrypt
  //var bytes  = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');
  //var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  //console.log(decryptedData); // [{id: 1}, {id: 2}]

  //STATE INICIAL

  const initialState = {
    tieneAcceso: 2,
    sessiondatagestores: [],
    
  };

  // PROVEDOR
  const [state, setState] = useState(initialState);


 /*  const authenticationfn = async (datos) => {
    try {
      const respuesta = await Axios.post("/login", datos);
      if (respuesta.data.sms === "ok") {
        let  token= respuesta.data.token
        localStorage.setItem("accesTokenCmm", token);
        window.location = "/";
      } else {
        alert(respuesta.data.mensaje);
      }
    } catch (e) {}
  }; */

 
  const setearSesiondataFn = (data) => {
    setState({
      ...state,
      sessiondatagestores: data,
    });
  };


  const limpiarSesiondataFn = (e) => {
    setState({
      ...state,
      tieneAcceso: 2,
      sessiondatagestores: [],
    });
    localStorage.clear()
    window.location = "/";
  };

 
  return (
    <authContext.Provider
      value={{
        sessiondatagestores: state.sessiondatagestores,
        limpiarSesiondataFn,
        setearSesiondataFn
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};
export default AuthState;
