import Axios from "../config/Axios";
import TokenAuth from "../config/Token";

export const crearProgramacion = async (data) => {
  console.log("pasando data para api... ", data);
  try {
    const respuesta = await Axios.post("/createPlanificacion", data);
    return [respuesta.data];
    /* if (data.username !== "" && data.pasword !== "" && data.correo !== "") {
      TokenAuth(localStorage.getItem("accesTokengestores"));
      const respuesta = await Axios.post("/createUserAccount", data);
      return [respuesta.data];
    } else if (data.username === "") {
      return [
        {
          data: [],
          sms: "err",
          mensaje: "El campo username es obligatorio",
        },
      ];
    } else if (data.pasword === "") {
      return [
        {
          data: [],
          sms: "err",
          mensaje: "El campo password es obligatorio !",
        },
      ];
    } else if (data.correo === "") {
      return [
        {
          data: [],
          sms: "err",
          mensaje: "El número de correo es oligatorio !",
        },
      ];
    } */
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const allProgramacion = async () => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.get("/list");
    console.log(respuesta);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const llenarCamion = async () => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.get("/camiones");
    console.log("api camiones... ", respuesta.data);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};
export const llenarTipo = async () => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.get("/transaccion");
    console.log("api tipo... ", respuesta.data);

    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};
export const llenarProducto = async () => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.get("/comboProducto");
    console.log("api tipo... ", respuesta.data);

    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};
export const llenarCliente = async () => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.get("/comboCliente");
    console.log("api tipo... ", respuesta.data);

    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const llenarBodegas = async () => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.get("/comboBodega");
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const perfilData = async (usuario) => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.get("/perfilData/" + usuario);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const updateProgramacion = async (data) => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.post("/updateusuarios/", data);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};
