import React, { useState } from "react";
import TablaPanelProgramacions from "../Componentes/PanelProgramacion.componentes/tabla.panelProgramacion";
import ModalDefault from "../Componentes/modalDefault";
import { ReactComponent as AdminIcon } from "../svg/admin.svg";
import CrearEditarProgramacion from "../Componentes/PanelProgramacion.componentes/crearEditarProgramacion";

const PlanificacionDiaria = () => {
  const [modalCrearProgramacion, setmodalCrearProgramacion] = useState(false);
  const [actualiza, setActualiza] = useState(false);

  const modalActions = (modal, bandera) => {
    if (bandera === "crearProgramacion") {
      setmodalCrearProgramacion(modal);
    } else {
      alert("estoy fuera de crear Programacion");
    }
  };

  const actualizarTabla = () => {
    setmodalCrearProgramacion(false);
    setActualiza(!actualiza);
  };

  return (
    <>
      <div className="p-2 bg-white">
        <div>panelProgramacions</div>

        <button
          className="btn btn-success mx-1"
          onClick={() => {
            setmodalCrearProgramacion(true);
          }}
        >
          Crear
        </button>

        <TablaPanelProgramacions actualizar={actualiza} />
        <ModalDefault
          bandera={"crearProgramacion"}
          modal={modalCrearProgramacion}
          size={"lg"}
          icontitulo={<AdminIcon />}
          titulo={`Crear Programacion`}
          closeAction={modalActions}
        >
          <CrearEditarProgramacion crearrModalActualizar={actualizarTabla} />
        </ModalDefault>
      </div>
    </>
  );
};

export default PlanificacionDiaria;
