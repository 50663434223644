import React, { useState } from "react";
import TablaPanelUsuarios from "../Componentes/PanelUsuarios.componentes/tabla.panelUsuarios";
import ModalDefault from "../Componentes/modalDefault";
import { ReactComponent as AdminIcon } from "../svg/admin.svg";
import CrearEditarUsuario from "../Componentes/PanelUsuarios.componentes/crearEditarUsuario";

const PanelUsuarios = () => {
  const [modalCrearUsuario, setmodalCrearUsuario] = useState(false);
  const [actualiza, setActualiza] = useState(false);

  const modalActions = (modal, bandera) => {
    if (bandera === "crearusuario") {
      setmodalCrearUsuario(modal);
    } else {
      alert("estoy fuera de crear usuario");
    }
  };

  const actualizarTabla = () => {
    setmodalCrearUsuario(false);
    setActualiza(!actualiza);
  };

  return (
    <>
      <div className="p-2 bg-white">
        <div>panelUsuarios</div>

        <button
          className="btn btn-success mx-1"
          onClick={() => {
            setmodalCrearUsuario(true);
          }}
        >
          Crear
        </button>

        <TablaPanelUsuarios actualizar={actualiza} />
        <ModalDefault
          bandera={"crearusuario"}
          modal={modalCrearUsuario}
          size={"lg"}
          icontitulo={<AdminIcon />}
          titulo={`Crear Usuario`}
          closeAction={modalActions}
        >
          <CrearEditarUsuario crearrModalActualizar={actualizarTabla} />
        </ModalDefault>
      </div>
    </>
  );
};

export default PanelUsuarios;
