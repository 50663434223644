import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
const ModalDefault = (props) => {
  //normalize('NFD').replace(/[\u0300-\u036f]/g,"")

  const modal = props.modal
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(modal)
  }, [modal])

  const cerrarModal = () => {
    props.closeAction(!modal, props.bandera)
  }

  return (
    <Modal
      isOpen={state}
      toggle={() => { }}
      centered
      size={props.size}
    >
      <ModalHeader
        toggle={() => {
          setState(!modal);
          cerrarModal()
        }}
        style={{ background: "white" }}
        className="border"
      >
        <label style={{ margin: "0", fontSize: "14px" }} >{props.icontitulo}{props.titulo}</label>
      </ModalHeader>
      <ModalBody>
        {props.children}
      </ModalBody>
    </Modal>
  );
};

export default ModalDefault;