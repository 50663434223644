import React, { useState, useEffect } from "react";
import DataTableDefault from "../ReactDataTable";
import { allProgramacion } from "../../api/programacionDiaria";
import moment from "moment";
import ModalDefault from "../modalDefault";
import { ReactComponent as AdminIcon } from "../../svg/admin.svg";
import CrearEditarProgramacion from "./crearEditarProgramacion";
const TablaPanelProgramacion = (props) => {
  const [planificacion, setplanificacion] = useState([]);
  const [modalPerfil, setModalPerfil] = useState(false);
  const [edit, editModall] = useState(false);

  const [selectData, setselectData] = useState(null);

  const columns = [
    {
      name: "Fecha",
      center: true,
      selector: (row) => moment(row.fecha).format("DD/MM/YYYY"),
    },
    {
      name: "Cliente",
      center: true,
      selector: (row) => row.cliente_entregado,
    },
    {
      name: "Direccion",
      center: true,
      selector: (row) => row.direccion_cliente,
    },
    {
      name: "Material",
      center: true,
      selector: (row) => row.sub_linea,
    },
    {
      name: "Transaccion",
      center: true,
      selector: (row) => row.tipo_transaccion,
    },
    {
      name: "Transporte",
      center: true,
      selector: (row) => row.placas,
    },
    {
      name: "Estado",
      center: true,
      selector: (row) => row.estado_prog,
    },
    {
      name: "Opciones",
      center: true,
      cell: (row) => opciones(row),
    },
  ];

  const opciones = (row) => {
    return (
      <>
        <div>
          <button
            className="btn btn-primary mx-1"
            onClick={() => {
              setselectData(row);
              setModalPerfil(true);
              editModall(true);
            }}
          >
            edit
          </button>
        </div>
        <div>
          <button
            className="btn btn-danger mx-1"
            onClick={() => {
              setselectData(row);
              setModalPerfil(true);
            }}
          >
            Trash
          </button>
        </div>
      </>
    );
  };

  useEffect(() => {
    initComponent();
  }, [props.actualizar]);

  const initComponent = async () => {
    const respuesta = await allProgramacion();
    if (respuesta[0].sms === "ok") {
      setplanificacion(respuesta[0].data);
      return;
    }
  };

  const modalActions = (modal, bandera) => {
    if (bandera === "perfil") {
      setModalPerfil(modal);
    } else {
      alert("estoy fuera del perfil");
    }
  };

  return (
    <>
      <DataTableDefault
        data={planificacion}
        columns={columns}
        title={"Listado de la Planificacion"}
      />
      <ModalDefault
        bandera={"perfil"}
        modal={modalPerfil}
        size={editModall ? "xl" : "lg"}
        icontitulo={<AdminIcon width={250} />}
        titulo={`Perfil de ${selectData ? selectData.usuarios : ""}`}
        closeAction={modalActions}
      >
        <CrearEditarProgramacion selectData={selectData} />
      </ModalDefault>
      {/*  <ModalDefault bandera={"otracosa"} modal={false} size={"sm"} icontitulo={<AdminIcon/>} titulo={"Titulo modal"} closeAction={modalActions}>
                modal de editar usuario
            </ModalDefault> */}
    </>
  );
};

export default TablaPanelProgramacion;
