import React from "react";
import DataTable from "react-data-table-component";
import { customStylesTable } from "../customStyles/dataTable";
const DataTableDefault = (props) => {
  //normalize('NFD').replace(/[\u0300-\u036f]/g,"")

  const paginationController = {
    rowsPerPageText: "Filas por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos los datos",
  };
  return (
    <div className="">
      <DataTable
        columns={props.columns}
        data={props.data}
        title={props.title}
        pagination
        paginationComponentOptions={paginationController}
        fixedHeader={true}
        fixedHeaderScrollHeight={props.height}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[5, 10, 20]}
        customStyles={customStylesTable}
        pointerOnHover={true}
        highlightOnHover={true}

        //progressPending={true}
      />
    </div>
  );
};

export default DataTableDefault;
