import React, { useState, useEffect } from "react";
import DataTableDefault from "../ReactDataTable";
import { allProductos } from "../../api/productos";
import moment from "moment";
import ModalDefault from "../modalDefault";
import { ReactComponent as AdminIcon } from "../../svg/admin.svg";
import CrearEditarProductos from "./crearEditarProductos";
const TablaPanelProductos = (props) => {
  const [productos, setProductos] = useState([]);
  const [modalPerfil, setModalPerfil] = useState(false);
  const [selectData, setselectData] = useState(null);

  const columns = [
    {
      name: "Item",
      center: true,
      selector: (row) => row.item,
    },
    {
      name: "Producto Nombre",
      center: true,
      selector: (row) => row.producto_nombre,
    },
    {
      name: "Cod-Linea",
      center: true,
      selector: (row) => row.cod_linea,
    },
    {
      name: "Cod-SubLinea",
      center: true,
      selector: (row) => row.cod_sublinea,
    },
    {
      name: "Sub-Linea",
      center: true,
      selector: (row) => row.sub_linea,
    },
    {
      name: "Medida",
      center: true,
      selector: (row) => row.medida,
    },
    {
      name: "Estado_prod",
      center: true,
      selector: (row) => row.estado_prod,
      //cell: (row) => (row.estado_prod === 1 ? "Activo" : "Inactivo"),
    },
    {
      name: "Opciones",
      center: true,
      cell: (row) => opciones(row),
    },
  ];

  const opciones = (row) => {
    return (
      <>
        <div>
          <button
            className="btn btn-primary mx-1"
            onClick={() => {
              setselectData(row);
              setModalPerfil(true);
            }}
          >
            edit
          </button>
        </div>
        <div>
          <button
            className="btn btn-danger mx-1"
            onClick={() => {
              setselectData(row);
              setModalPerfil(true);
            }}
          >
            Trash
          </button>
        </div>
      </>
    );
  };

  useEffect(() => {
    initComponent();
  }, [props.actualizar]);

  const initComponent = async () => {
    const respuesta = await allProductos();
    if (respuesta[0].sms === "ok") {
      setProductos(respuesta[0].data);
      return;
    }
  };

  const modalActions = (modal, bandera) => {
    if (bandera === "perfil") {
      setModalPerfil(modal);
    } else {
      alert("estoy fuera del perfil");
    }
  };

  return (
    <>
      <DataTableDefault
        data={productos}
        columns={columns}
        title={"Listado de productos"}
      />
      <ModalDefault
        bandera={"perfil"}
        modal={modalPerfil}
        size={"lg"}
        icontitulo={<AdminIcon width={250} />}
        titulo={`Perfil de ${selectData ? selectData.productos : ""}`}
        closeAction={modalActions}
      >
        <CrearEditarProductos selectData={selectData} />
      </ModalDefault>
      {/*  <ModalDefault bandera={"otracosa"} modal={false} size={"sm"} icontitulo={<AdminIcon/>} titulo={"Titulo modal"} closeAction={modalActions}>
                modal de editar usuario
            </ModalDefault> */}
    </>
  );
};

export default TablaPanelProductos;
