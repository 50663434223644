import React, { useContext, useState, useEffect, useRef } from "react";
import { authenticationfn } from "../api/auth";
import { toast } from "react-toastify";
import Logocmm from "../images/almetales.png";
import { socket } from "../config/urls";

import { motion } from "framer-motion";
import { Button, Form, Input, Divider, Alert } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import CustomIcon from "../components/util-components/CustomIcon";

const Login = () => {
  const ref = useRef([]);
  const showMessage = "";
  const message = "";
  const obj = {
    username: "",
    password: "",
  };
  const [state, setstate] = useState(obj);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    toast.info("iniciando login", { position: "bottom-left" });
    ref.current["username"].focus();
  }, []);

  socket.on("xx", (data) => {
    alert(data);
  });

  socket.emit("xxx", "122");

  const onChange = (e) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const logear = async (e) => {
    //alert("viendo valor...", process.env.REACT_PRUEBA);

    const res = await authenticationfn(state);
    switch (res[0].sms) {
      default:
        toast.error(`${res[0].mensaje}`);
        break;
    }
  };

  const detectarBotonPresionado = (e) => {
    if (e.key === "Enter") {
      if (e.target.name === "username") ref.current["password"].focus();
      else if (e.target.name === "password") logear();
    }
  };

  return (
    <>
      <div
        className=" d-flex  justify-content-center align-items-end flex-column px-5 "
        style={{ height: "100vh", background: "#1e3875" }}
      >
        {/*begin::Content body*/}
        {/*begin::Signin*/}
        <div
          className=" bg-white  bordercircular card  w-60  "
          style={{ opacity: "1", minWidth: "35%", height: "90vh" }}
        >
          {/*begin::Form*/}
          <form className="form card-body mx-2 my-2 " id="">
            {/*begin::Title*/}
            <div className="pb-13 pt-lg-0 pt-5 text-center">
              <img src={Logocmm} alt="" style={{ width: "180px" }} />
              <h6 className="font-weight-bolder text-dark font-size-h6  mx-4 my-3">
                ADMINISTRACIÓN
              </h6>
            </div>
            {/*begin::Title*/}
            {/*begin::Form group*/}
            <div className="form-group ">
              <label className="font-size-h6 font-weight-bolder text-dark my-1">
                Usuario
              </label>
              <input
                className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                type="text"
                id="username"
                name="username"
                ref={(r) => (ref.current["username"] = r)}
                value={state.username}
                onChange={onChange}
                onKeyPress={detectarBotonPresionado}
              />
            </div>
            <br />
            {/*end::Form group*/}
            {/*begin::Form group*/}
            <div className="form-group">
              <div className="d-flex justify-content-between mt-n5">
                <label className="font-size-h6 font-weight-bolder text-dark py-1">
                  Contraseña
                </label>
                {/*<a href="javascript:;" class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5" id="kt_login_forgot">Forgot Password ?</a>*/}
              </div>
              <input
                className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                type="password"
                name="password"
                id="password"
                ref={(r) => (ref.current["password"] = r)}
                value={state.password}
                onChange={onChange}
                onKeyPress={detectarBotonPresionado}
              />
            </div>
            {/*end::Form group*/}
            {/*begin::Action*/}
            <div className="pb-lg-0 mb-3 mt-5">
              <button
                type="button"
                onClick={logear}
                className="btn btn-primary font-weight-bolder py-2 w-100 "
              >
                Iniciar sesión
              </button>
            </div>
            {/*end::Action*/}
          </form>
          {/*end::Form*/}
        </div>
        {/*end::Signin*/}
      </div>
    </>
  );
};

export default Login;
