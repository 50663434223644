const TABLE_HEAD_COLOR="#e9e9e9"
const TABLE_TEXT_COLOR="black"


export const customStylesTable = {
   
    rows: {
        style: {
            minHeight: '50px', // override the row height
        },
    },
    headCells: {
        style: {
            color:TABLE_TEXT_COLOR,
            background:TABLE_HEAD_COLOR,
            fontWeight:"bold",
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '0px', // override the cell padding for data cells
            paddingRight: '0px',
        },
    },
   
};