import React, { useState, useEffect } from "react";
import { Form, FormGroup, Input, Label, Button, Col } from "reactstrap";
import { crearProductos, updateProductos } from "../../api/productos";
import { toast } from "react-toastify";
const CrearEditarProductos = (props) => {
  const [editar, setEditar] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [productos, setProductos] = useState([]);
  const [loadingCrearProductos, setLoadingCrearProductos] = useState(false);

  const [state, setState] = useState({
    username: "",
    password: "",
    correo: "",
    nivel: "",
    id: "",
  });
  const [editState, setEditState] = useState({
    edit: "",
    valor: "",
    id: "",
  });

  const [respaldoEditField, setRespaldoEditField] = useState("");
  const habilitarEdicion = (e, edit) => {
    e.preventDefault();
    if (editState.edit === "") {
      setEditState({
        ...editState,
        edit: edit,
        id:
          edit === "correo"
            ? props.selectData.personas[0].persona_id
            : props.selectData.id,
      });
      setRespaldoEditField(state[edit]);
    } else {
      alert(
        "otro campo se encuentra en edicion , por favor guarde o cancele los cambios para continuar "
      );
    }
  };
  const cancelarEdicion = (e) => {
    e.preventDefault();
    setState({
      ...state,
      [editState.edit]: respaldoEditField,
    });
    setEditState({
      ...editState,
      edit: "",
      valor: "",
    });
    setRespaldoEditField("");
  };

  const editarFn = async (e) => {
    e.preventDefault();
    setButtonState(true);
    const res = await updateProductos(editState);
    switch (res[0].sms) {
      case "ok":
        toast.success(`${res[0].mensaje}`, {
          theme: "colored",
        });
        setButtonState(false);
        setEditState({
          ...editState,
          edit: "",
          id: "",
          valor: "",
        });

        break;
      default:
        toast.error(`${res[0].mensaje}`, {
          theme: "colored",
        });
        setButtonState(false);
        break;
    }
  };

  const editbtn = (campoeditar) => {
    if (editState.edit === campoeditar) {
      document.getElementById(campoeditar).disabled = false;
    }
    return editar ? (
      <Label sm={2}>
        {editState.edit !== campoeditar ? (
          <button
            className="btn btn-primary btn-sm"
            onClick={(e) => habilitarEdicion(e, campoeditar)}
          >
            editar
          </button>
        ) : editState.edit === campoeditar ? (
          <div className="">
            <button
              className="btn btn-danger btn-sm mx-1"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById(campoeditar).disabled = true;
                cancelarEdicion(e);
              }}
            >
              x
            </button>
            <button
              className="btn btn-primary btn-sm "
              disabled={editState.valor === "" ? true : false}
              onClick={(e) => editarFn(e)}
            >
              guardar
            </button>
          </div>
        ) : null}
      </Label>
    ) : null;
  };
  const ed = (name, valor) => {
    if (editState.edit === name) {
      setEditState({
        ...editState,
        valor: valor,
      });
    }
  };

  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

    ed(e.target.name, e.target.value);
  };

  const guardarProductos = () => {
    saveProductos();
  };

  const saveProductos = async () => {
    setLoadingCrearProductos(true);

    const respuesta = await crearProductos(state);
    if (respuesta[0].sms === "ok") {
      toast.success(respuesta[0].mensaje, { position: "bottom-left" });
      cerrarModalActualizarTablaProductos();
    } else {
      toast.error(respuesta[0].mensaje, { position: "bottom-left" });
    }
    setLoadingCrearProductos(false);
  };

  const cerrarModalActualizarTablaProductos = () => {
    props.crearrModalActualizar();
  };

  useEffect(() => {
    if (props.selectData) {
      setEditar(true);
      initEditar();
    }
  }, []);

  const initEditar = () => {
    setState({
      ...state,
      username: props.selectData.usuarios,
      nivel: props.selectData.nivel,
      correo:
        props.selectData.personas.length > 0
          ? props.selectData.personas[0].correo
          : "",
      id: props.selectData.id,
    });

    setEditState({
      ...editState,
      id: props.selectData.id,
    });
  };

  const editaux = () => {};

  return (
    <div className="row p-5">
      <Form inline className="col-md-12 px-5">
        <FormGroup row className="d-flex align-items-center">
          <Label for="username" sm={2}>
            username :
          </Label>
          <Col sm={editar ? 8 : 10}>
            <Input
              disabled={editar ? true : false}
              id="username"
              name="username"
              value={state.username}
              placeholder="Ingrese el usuario"
              type="text"
              onChange={onchange}
            />
          </Col>
          {editbtn("username")}
        </FormGroup>{" "}
        <FormGroup row className="d-flex align-items-center">
          <Label for="password" sm={2}>
            password :
          </Label>
          <Col sm={editar ? 8 : 10}>
            <Input
              disabled={editar ? true : false}
              id="password"
              name="password"
              placeholder={editar ? "Edite la clave" : "Ingrese clave"}
              type="text"
              value={state.password}
              onChange={onchange}
            />
          </Col>
          {editbtn("password")}
        </FormGroup>{" "}
        <FormGroup row className="d-flex align-items-center">
          <Label for="correo" sm={2}>
            Correo :
          </Label>
          <Col sm={editar ? 8 : 10}>
            <Input
              disabled={editar ? true : false}
              id="correo"
              name="correo"
              placeholder="Ingrese el correo electrónico"
              type="text"
              value={state.correo}
              onChange={onchange}
            />
          </Col>
          {editbtn("correo")}
        </FormGroup>{" "}
        <FormGroup row className="d-flex align-items-center">
          <Label for="nivel" sm={2}>
            Nivel :
          </Label>
          <Col sm={editar ? 8 : 10}>
            <Input
              disabled={editar ? true : false}
              id="nivel"
              name="nivel"
              placeholder="Ingrese el nivel de usuario"
              type="text"
              value={state.nivel}
              onChange={onchange}
            />
          </Col>
          {editbtn("nivel")}
        </FormGroup>{" "}
        <hr className="my-4" />
        {!editar ? (
          <Button
            color="primary"
            className="w-100"
            disabled={loadingCrearProductos ? true : false}
            onClick={(e) => {
              guardarProductos();
            }}
          >
            Guardar nuevo usuario
          </Button>
        ) : null}
      </Form>
    </div>
  );
};

export default CrearEditarProductos;
