import Axios from "../config/Axios";
import TokenAuth from "../config/Token";

export const crearUsuario = async (data) => {
  try {
    if (data.username !== "" && data.pasword !== "" && data.correo !== "") {
      TokenAuth(localStorage.getItem("accesTokengestores"));
      const respuesta = await Axios.post("/createUserAccount", data);
      return [respuesta.data];
    } else if (data.username === "") {
      return [
        {
          data: [],
          sms: "err",
          mensaje: "El campo username es obligatorio",
        },
      ];
    } else if (data.pasword === "") {
      return [
        {
          data: [],
          sms: "err",
          mensaje: "El campo password es oligatorio !",
        },
      ];
    } /* else if (data.correo === "") {
      return [
        {
          data: [],
          sms: "err",
          mensaje: "El número de correo es oligatorio !",
        },
      ];
    } */
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const allUsuarios = async () => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.get("/allUsuarios");
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const perfilData = async (usuario) => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.get("/perfilData/" + usuario);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const updateUsuarios = async (data) => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.post("/updateusuarios/", data);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};
