import React, { useEffect, useContext, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Login from "./pages/login";
import { socket } from "./config/urls";

import AuthContext from "./Context/auth/authContext";
import { authenticationDataFn } from "./api/auth";
import { toast } from "react-toastify";
import SideBar from "./Componentes/Sidebar/SideBar";
import PanelUsuarios from "./pages/panelUsuarios";
import Dashboard from "./pages/dashboard";
import Productos from "./pages/productos";
import ReporteCaja from "./pages/reporteCaja";
import AnalisisAbc from "./pages/analisisabc";
import PlanificacionDiaria from "./pages/planificacionDiaria";
import ReporteCompras from "./pages/reporteCompras";

const App = () => {
  const AuthenticationContext = useContext(AuthContext);
  const { sessiondatagestores, setearSesiondataFn, limpiarSesiondataFn } =
    AuthenticationContext;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("accesTokengestores")) {
      inicializar();
    }
  }, []);

  const inicializar = async () => {
    setLoading(true);
    const res = await authenticationDataFn();
    console.log(res[0].data);
    switch (res[0].sms) {
      case "ok":
        setearSesiondataFn(res[0].data);
        setLoading(false);
        break;
      default:
        toast.error(`${res[0].mensaje}`);
        localStorage.clear();
        window.location = "/";
        break;
    }
  };

  return (
    <Router>
      {localStorage.getItem("accesTokengestores") ? (
        sessiondatagestores.length === 0 ? (
          loading ? (
            <h6>Cargando...</h6>
          ) : (
            <h5>No hay data disponible</h5>
          )
        ) : (
          /*  <div>
          <h6>usuario: {sessiondatagestores[0].username}</h6>
          <h6>correo: {sessiondatagestores[0].personas[0].correo}</h6>
          <h6>estado: {sessiondatagestores[0].estado===1?"activo":"inactivo"}</h6>
          <div>
            <button className="btn btn-primary" onClick={limpiarSesiondataFn}>Cerrar sesion</button>
          </div>
          </div> */

          <SideBar>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/usuarios" element={<PanelUsuarios />} />

              <Route path="/productos" element={<Productos />} />
              <Route path="/reporteCaja" element={<ReporteCaja />} />
              <Route path="/analisisabc" element={<AnalisisAbc />} />
              <Route path="/facturacion" element={<AnalisisAbc />} />
              <Route
                path="/planificaciondiaria"
                element={<PlanificacionDiaria />}
              />
              <Route
                path="/reportecompras"
                element={<ReporteCompras />}
              />
              <Route path="*" element={<> not found</>} />
            </Routes>{" "}
          </SideBar>
        )
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<>No es posible acceder</>} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
