import Axios from "../config/Axios";
import TokenAuth from "../config/Token";

export const allProgramacion = async () => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.get("/list");
    console.log(respuesta);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const llenarBodegas = async () => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.get("/comboBodega");
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const perfilData = async (usuario) => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.get("/perfilData/" + usuario);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const updateProgramacion = async (data) => {
  try {
    TokenAuth(localStorage.getItem("accesTokengestores"));
    const respuesta = await Axios.post("/updateusuarios/", data);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};
