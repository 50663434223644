import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

/* const scores = [1560, 2000, 560, 148];
const labels = ["CHATARRA", "PET", "otra", "mas"]; */

const options = {
  fill: true,
  animations: true,
  scales: {
    y: {
      min: 0,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "top",
      align: "center",
    },
  },
};

export default function BarChartVentas(props) {
  console.log(props.label);
  console.log(props.pesos);

  let labels = props.label;
  let pesitos = props.pesos;
  const data = useMemo(
    function () {
      return {
        datasets: [
          {
            label: "MATERIALES MAS VENDIDOS",
            tension: 1,
            data: pesitos,
            borderColor: "rgb(40, 168, 64)",
            backgroundColor: "DodgerBlue",
          },
        ],
        labels,
      };
    },
    [pesitos]
  );

  return (
    <div className="App">
      <Bar data={data} options={options} />
    </div>
  );
}
